<div class="app-wrapper">
  <div class="side-navbar" [class.expanded]="expanded">
    <div class="hamburger" (click)="toggleNavBar()" role="presentation">
      <mat-icon>menu</mat-icon>
    </div>
    <div class="nav-items">
      @for (item of navItems; track item.label) {
        @if (item.routerLink) {
          <a
            [ngClass]="{ hmt: !item.active }"
            class="nav-item"
            [matTooltip]="item.label"
            [matTooltipDisabled]="expanded"
            matTooltipPosition="after"
            [routerLink]="item.routerLink">
            <mat-icon>
              <img class="icon" [src]="item.icon" [alt]="item.label" />
            </mat-icon>
            <span class="nav-text">{{ item.label }}</span>
          </a>
        } @else {
          <a class="nav-item" [matTooltip]="item.label" [matTooltipDisabled]="expanded" matTooltipPosition="after">
            <mat-icon>
              <img class="icon" [src]="item.icon" [alt]="item.label" />
            </mat-icon>
            <span class="nav-text">{{ item.label }}</span>
          </a>
        }
      }
    </div>
    <div class="navbar">
      <div class="navbar-item">
        <div class="rotated-text">
          <span>Dwara<span class="hmt">.One</span></span>
          <mat-icon fontSet="material-icons-outlined" class="help-icon">
            <img src="assets/icons/question.svg" alt="question mark" />
          </mat-icon>
        </div>
      </div>
    </div>
  </div>
  <div class="app-content">
    <app-header></app-header>
    <div class="content-wrapper">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
